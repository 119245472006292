import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../cards/neb-preallocate-line-item-card';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';
import '../../controls/buttons/neb-button-patient';

import { html, css, LitElement, nothing } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../packages/neb-utils/formatters';
import { currency } from '../../../../packages/neb-utils/masks';
import surveys from '../../../library/userpilot/surveys';
import {
  CSS_SPACING,
  CSS_SMALL_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_2,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_COLOR_HIGHLIGHT,
  CSS_BANNER_INFO_COLOR,
} from '../../../styles';
import {
  calculateCurrentBalance,
  calculatePreallocateInfoMap,
  calculatePreallocationTotal,
  getPreallocateAction,
  preallocateCharges,
} from '../../../utils/preallocate-payment-util';
import '../../controls/buttons/neb-button-give-feedback';

export const ELEMENTS = {
  lineItemList: {
    id: 'line-item-list',
  },
  lineItemCard: {
    selector: '[id^=line-item-card-]',
  },
  summary: {
    id: 'summary',
  },
  totalDueToday: {
    id: 'total-due-today',
  },
  selectedCharges: {
    id: 'selected-charges',
  },
  applyCreditsCheckbox: {
    id: 'apply-credits-checkbox',
  },
  applyCreditsAmount: {
    id: 'apply-credits-amount',
  },
  remainingPatientBalance: {
    id: 'remaining-patient-balance',
  },
  emptyList: {
    id: 'empty-list',
  },
  confirmButton: {
    id: 'confirm-button',
  },
  feedbackButton: {
    id: 'feedback-button',
  },
  loadMoreButton: {
    id: 'load-more-button',
  },
  loadMoreSpinner: {
    id: 'load-more-spinner',
  },
  filterByRelationshipSection: {
    id: 'filter-by-relationship-section',
  },
  relationshipButton: {
    selector: '[id^=relationship-button-]',
  },
};

class NebFormPreallocatePayment extends LitElement {
  static get properties() {
    return {
      disableLoadMoreButton: Boolean,
      patient: Object,
      preallocateInfoMap: Object,
      relationships: Array,
      remainingPatientBalance: Number,
      remainingPatientCredits: Number,

      __hasPreallocationFeedbackButtonFF: Boolean,
      __hasLSCreditAllocationFF: Boolean,
      __loading: Boolean,
      __useCreditAmount: Number,
      __useCreditAmountInput: String,
      __hasPreallocationFamilyFF: Boolean,
      __selectedPatientId: String,
    };
  }

  updated(changedProperties) {
    if (changedProperties.has('patient') && this.__selectedPatientId === '') {
      this.__selectedPatientId = this.patient.id;
    }
  }

  initState() {
    this.__selectedPatientId = '';
    this.__useCreditAmount = 0;
    this.__useCreditAmountInput = centsToCurrency(0);

    this.onLoadMore = () => {};
  }

  initHandlers() {
    this.handlers = {
      onClickRelationshipButton: async selectedPatientId => {
        this.__selectedPatientId = selectedPatientId;

        if (this.preallocateInfoMap[selectedPatientId].pageNumber === 0) {
          await this.__loadMore();
        }
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  async connectedCallback() {
    await super.connectedCallback();

    this.__hasPreallocationFeedbackButtonFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_PREALLOCATION_FEEDBACK_BUTTON,
    );

    this.__hasPreallocationFamilyFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_PREALLOCATION_FAMILY,
    );

    this.__hasLSCreditAllocationFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_CREDIT_PREALLOCATION,
    );
  }

  static get styles() {
    return css`
      #summary {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: ${CSS_SPACING};
        background: ${CSS_COLOR_GREY_4};
        padding: ${CSS_SPACING};
        border-left: 1px solid ${CSS_COLOR_GREY_2};
      }

      #line-item-list > .list {
        padding: ${CSS_SPACING};
        gap: ${CSS_SPACING};
        height: 100%;
      }

      #empty-list {
        color: ${CSS_BANNER_INFO_COLOR};
        align-items: center;
        text-align: center;
        justify-content: center;
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        font-size: ${CSS_FONT_SIZE_HEADER};
      }

      .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .total-due-today-box {
        color: ${CSS_BANNER_INFO_COLOR};
        background: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
        padding: ${CSS_SPACING};
        border: 1px solid ${CSS_COLOR_HIGHLIGHT};
        padding: ${CSS_SPACING};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .form {
        display: flex;
        flex-direction: row;
        height: 100%;
      }

      .celebration-icon {
        height: 125px;
        width: 125px;
        fill: ${CSS_BANNER_INFO_COLOR};
      }

      .padding-horizontal {
        padding: 0 ${CSS_SPACING};
      }

      .list {
        flex: 7;
        flex-direction: column;
        display: flex;
      }

      .content-container {
        overflow-y: scroll;
      }

      .summary-title {
        margin: 0 0 ${CSS_SPACING} 0;
      }

      .flex-separator,
      .summary-button-container,
      .apply-credits-box :last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .summary-button-container {
        justify-content: flex-start;
        gap: 40px;
      }

      .apply-credits-box {
        gap: 12px;
      }

      .apply-credits-box :last-child {
        gap: 5px;
      }

      .use-credit-amount {
        width: 80px;
        padding: 5px;
      }

      .spacer {
        flex: 1 0 0;
      }

      .line-item-card {
        padding: ${CSS_SPACING};
        border: 1px solid black;
      }

      .button {
        width: fit-content;
      }

      .relationship-filter {
        padding: ${CSS_SPACING_ROW} ${CSS_SMALL_SPACING};
        border: 1px solid ${CSS_COLOR_GREY_2};
        border-radius: 5px;
        margin: 20px;
        margin-bottom: 0;
      }

      .filter-by-relationship-label {
        margin-bottom: ${CSS_SMALL_SPACING};
      }

      .relationship-button-container {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        overflow-x: auto;
        max-height: 68px;
      }

      .center {
        height: 100%;
        width: 100%;
      }
    `;
  }

  async __loadMore() {
    this.__loading = true;

    try {
      await this.onLoadMore(this.preallocateInfoMap, this.__selectedPatientId);
    } finally {
      this.__loading = false;
    }
  }

  __onChangeItemGroup(newItems, groupIdx) {
    const formattedItems = this.preallocateInfoMap[
      this.__selectedPatientId
    ].itemGroups[groupIdx].items.map((item, itemIdx) => ({
      ...item,
      ...newItems[itemIdx],
    }));

    const newInfoMapForPatient = {
      ...this.preallocateInfoMap[this.__selectedPatientId],
      itemGroups: this.preallocateInfoMap[
        this.__selectedPatientId
      ].itemGroups.map((group, groupIdx2) => ({
        ...group,
        items: groupIdx === groupIdx2 ? formattedItems : group.items,
      })),
    };

    this.preallocateInfoMap = {
      ...this.preallocateInfoMap,
      [this.__selectedPatientId]: newInfoMapForPatient,
    };

    this.__onChangeSwitch({ value: this.__useCreditAmount > 0 });
  }

  __getMaximumUseCreditAmount() {
    const selectedChargesTotal = calculatePreallocationTotal(
      this.preallocateInfoMap?.[this.__selectedPatientId].itemGroups || [],
    );

    return selectedChargesTotal > this.remainingPatientCredits
      ? this.remainingPatientCredits
      : selectedChargesTotal;
  }

  __onChangeSwitch(e) {
    this.__useCreditAmount = e.value ? this.__getMaximumUseCreditAmount() : 0;

    this.__useCreditAmountInput = centsToCurrency(this.__useCreditAmount);
  }

  __onChangeAmount(e) {
    this.__useCreditAmountInput = e.value;

    if (e.event !== 'blur') return;
    const amount = currencyToCents(e.value);
    const maximumUseCreditAmount = this.__getMaximumUseCreditAmount();

    this.__useCreditAmount =
      amount > maximumUseCreditAmount ? maximumUseCreditAmount : amount;

    this.__useCreditAmountInput = centsToCurrency(this.__useCreditAmount);
  }

  __renderEmptyList() {
    if (this.preallocateInfoMap === null) {
      return nothing;
    }

    if (this.__loading) {
      return html`
        <div
          id="${ELEMENTS.loadMoreSpinner.id}"
          class="load-button spinner-container center"
        >
          <neb-loading-spinner></neb-loading-spinner>
        </div>
      `;
    }

    return html`
      <div id="${ELEMENTS.emptyList.id}" class="list">
        <neb-icon class="celebration-icon" icon="neb:celebration"></neb-icon>
        <div>
          Great news!
          <br />
          There are no outstanding balances for this patient at this time.
        </div>
      </div>
    `;
  }

  __renderLoadMore() {
    if (this.__loading) {
      return html`
        <div
          id="${ELEMENTS.loadMoreSpinner.id}"
          class="load-button spinner-container"
        >
          <neb-loading-spinner></neb-loading-spinner>
        </div>
      `;
    }

    return html`
      <neb-button
        id="${ELEMENTS.loadMoreButton.id}"
        class="load-button"
        label="LOAD MORE SERVICE DATES"
        .role="${BUTTON_ROLE.OUTLINE}"
        .onClick="${() => this.__loadMore()}"
        .disabled="${this.preallocateInfoMap?.[this.__selectedPatientId]
          .disableLoadMore}"
      ></neb-button>
    `;
  }

  __renderFilterByRelationship() {
    if (!this.__hasPreallocationFamilyFF || !this.relationships?.length) {
      return nothing;
    }

    if (this.__selectedPatientId === '') {
      this.__selectedPatientId = this.patient.id;
    }

    const relationships = [
      {
        relatedPatientId: this.patient.id,
        patient: {
          id: this.patient.id,
          name: {
            first: this.patient.name.split(', ')[1],
            last: this.patient.name.split(', ')[0],
          },
        },
      },
      ...this.relationships,
    ];

    return html`<div
      id="${ELEMENTS.filterByRelationshipSection.id}"
      class="relationship-filter"
    >
      <div class="filter-by-relationship-label">Filter by Relationship</div>
      <div class="relationship-button-container">
        ${relationships.map(
          (relationship, idx) =>
            html`<neb-button-patient
              id="relationship-button-${idx}"
              .patient="${relationship.patient}"
              ?active="${this.__selectedPatientId ===
              relationship.relatedPatientId}"
              .onClick="${this.handlers.onClickRelationshipButton}"
            ></neb-button-patient>`,
        )}
      </div>
    </div>`;
  }

  __renderCardList() {
    return html`
      <div id="${ELEMENTS.lineItemList.id}" class="list">
        <div class="list">
          ${this.preallocateInfoMap[this.__selectedPatientId].itemGroups.map(
            (_, idx) => html`
              <neb-preallocate-line-item-card
                id="line-item-card-${idx}"
                .preallocateItemGroup="${this.preallocateInfoMap[
                  this.__selectedPatientId
                ].itemGroups[idx]}"
                .onChange="${items => this.__onChangeItemGroup(items, idx)}"
              ></neb-preallocate-line-item-card>
            `,
          )}
          ${this.__renderLoadMore()}
        </div>
      </div>
    `;
  }

  __renderTotalDueToday() {
    return html`
      <div class="flex-separator total-due-today-box">
        <span> Total Due Today: </span>
        <span id="${ELEMENTS.totalDueToday.id}">
          ${centsToCurrency(
            calculatePreallocateInfoMap(this.preallocateInfoMap) -
              this.__useCreditAmount,
          )}
        </span>
      </div>
    `;
  }

  __renderSelectedCharges() {
    if (!this.__hasLSCreditAllocationFF) return nothing;

    const totalSelectedCharges = calculatePreallocateInfoMap(
      this.preallocateInfoMap,
    );

    return html`
      <div class="flex-separator padding-horizontal selected-charges-box">
        <span> Selected Charges:</span>
        <b id="${ELEMENTS.selectedCharges.id}">
          ${centsToCurrency(totalSelectedCharges)}
        </b>
      </div>
    `;
  }

  __renderApplyCredits() {
    if (!this.__hasLSCreditAllocationFF) return nothing;

    return html`
      <div class="flex-separator apply-credits-box padding-horizontal">
        <div class="flex-separator">
          <neb-switch
            id="${ELEMENTS.applyCreditsCheckbox.id}"
            name="${ELEMENTS.applyCreditsCheckbox.id}"
            ?on="${this.__useCreditAmount > 0}"
            .disabled="${this.remainingPatientCredits === 0}"
            .onChange="${e => this.__onChangeSwitch(e)}"
          ></neb-switch>
          Apply Unallocated Payments
        </div>
        <div>
          <neb-textfield
            id="${ELEMENTS.applyCreditsAmount.id}"
            name="${ELEMENTS.applyCreditsAmount.id}"
            class="use-credit-amount"
            .value="${this.__useCreditAmountInput}"
            .onChange="${e => this.__onChangeAmount(e)}"
            .disabled="${this.remainingPatientCredits === 0 ||
            this.__useCreditAmount === 0}"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            label=" "
            helper=" "
          ></neb-textfield>
          <span> of </span>
          <b>${centsToCurrency(this.remainingPatientCredits)}</b>
        </div>
      </div>
    `;
  }

  __renderButtons() {
    const label = getPreallocateAction(
      this.preallocateInfoMap?.[this.__selectedPatientId]?.itemGroups,
      this.__useCreditAmount,
    );

    return html`
      <div class="summary-button-container">
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="button"
          label="${label}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .disabled="${this.__hasNoCheckedItems()}"
          .onClick="${() =>
            preallocateCharges({
              patient: this.patient,
              preallocateItemGroups: Object.values(
                this.preallocateInfoMap || {},
              ).flatMap(info => info.itemGroups),
              creditsAmount: this.__useCreditAmount,
              onDismiss: this.onDismiss,
            })}"
          unelevated
        ></neb-button>
        ${this.__hasPreallocationFeedbackButtonFF
          ? html`
              <neb-button-give-feedback
                id="${ELEMENTS.feedbackButton.id}"
                .surveyCode="${surveys.PREALLOCATION_FEEDBACK}"
              ></neb-button-give-feedback>
            `
          : nothing}
      </div>
    `;
  }

  __renderSummary() {
    return html`
      <div id="${ELEMENTS.summary.id}">
        <h2 class="summary-title padding-horizontal">Summary</h2>
        ${this.__renderSelectedCharges()} ${this.__renderApplyCredits()}
        ${this.__renderTotalDueToday()}
        ${this.__renderRemainingPatientBalance()}
        <div class="spacer"></div>
        ${this.__renderButtons()}
      </div>
    `;
  }

  __hasNoCheckedItems() {
    const hasPreallocateItems = Boolean(
      this.preallocateInfoMap?.[this.patient.id]?.itemGroups.length,
    );

    const totalPreallocation = calculatePreallocateInfoMap(
      this.preallocateInfoMap,
    );

    return hasPreallocateItems && totalPreallocation === 0;
  }

  __renderRemainingPatientBalance() {
    const balance = calculateCurrentBalance(
      this.remainingPatientBalance,
      this.preallocateInfoMap?.[this.patient.id].itemGroups || [],
      this.__useCreditAmount,
    );

    const balanceLabel =
      balance >= 0 ? 'Remaining Patient Balance' : 'Remaining Patient Credit';
    const balanceValue = centsToCurrency(Math.abs(balance));

    return html`
      <div
        id="${ELEMENTS.remainingPatientBalance.id}"
        class="flex-separator padding-horizontal"
      >
        <span>${balanceLabel}:</span>
        <span> ${balanceValue} </span>
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="list content-container">
        ${this.__renderFilterByRelationship()}
        ${this.preallocateInfoMap?.[this.__selectedPatientId]?.itemGroups.length
          ? this.__renderCardList()
          : this.__renderEmptyList()}
      </div>
    `;
  }

  render() {
    return html`
      <div class="form">
        ${this.__renderContent()} ${this.__renderSummary()}
      </div>
    `;
  }
}

customElements.define(
  'neb-form-preallocate-payment',
  NebFormPreallocatePayment,
);
