import { APPOINTMENT_BILLING_TYPE } from '../../packages/neb-api-client/src/mappers/appointment-billing-info-mapper';
import { BILL_TYPES } from '../../packages/neb-api-client/src/mappers/patient-case-mapper';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../packages/neb-redux/neb-redux-store';
import { calculateAutoAllocateDebits } from '../../packages/neb-utils/auto-allocate-util';
import { CODE_PAYMENTS } from '../../packages/neb-utils/constants';
import { parseDate } from '../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../packages/neb-utils/formatters';
import { allocateCreditsToPreallocationLineItems } from '../api-clients/preallocate-payment';
import { getLatestBilledStatus } from '../formatters/line-items';
import { openError, openSuccess } from '../store';

export const PREALLOCATE_SCREEN_PAGE_SIZE = 50;

export const ERROR_BANNER_MESSAGE =
  'There was an issue loading outstanding charges. You can still proceed with taking a payment.';

export const ERROR_LOADING_MORE =
  'There was an error when loading more charges.';

export const ALLOCATE_ACTION_SUCCESS_BANNER =
  'Payment(s) successfully allocated to the selected charges.';

export const ALLOCATE_ACTION_ERROR_BANNER =
  'A system error prevented allocating patient payments to the selected charges';

const COPAY_CODES = [CODE_PAYMENTS.COPAY.code, CODE_PAYMENTS['PR-3'].code];
const DEDUCTIBLE_CODES = [
  CODE_PAYMENTS.DEDUCTIBLE.code,
  CODE_PAYMENTS['PR-1'].code,
];
const COINSURANCE_CODES = [
  CODE_PAYMENTS.COINSURANCE.code,
  CODE_PAYMENTS['PR-2'].code,
];
export const PREALLOCATE_ACTIONS = {
  makePayment: 'Make Payment',
  allocate: 'Allocate',
  allocateAndPay: 'Allocate & Pay',
};

const getCardTitle = lineItem => {
  const title = [];

  if (lineItem.encounter?.serviceDate) {
    title.push(
      parseDate(lineItem.encounter.serviceDate).format('MMMM D, YYYY'),
    );
  } else {
    title.push(parseDate(lineItem.dateOfService).format('MMMM D, YYYY'));
  }

  if (lineItem.providerName) {
    title.push(lineItem.providerName);
  }

  return title.join(' - ');
};

const getProviderName = (lineItem, providers) => {
  const provider = providers.find(
    pr => pr.id === lineItem.encounterCharge?.providerId,
  );

  if (!provider) return null;

  return `${provider?.firstName} ${provider?.lastName}`;
};

const getLocationName = (lineItem, locations) => {
  if (locations.length <= 1) {
    return '';
  }

  const location = locations.find(
    loc => loc.data.id === lineItem.encounterCharge?.locationId,
  );

  if (!location) return '';

  return location.label;
};

const getEncounterData = (li, encounters) => {
  const encounter = encounters.find(
    e => e.id === li.encounterCharge?.encounterId,
  );

  if (!encounter) return null;

  return {
    ...encounter,
    encounterChargesCount: li.encounterCharge?.encounterChargesCount || 1,
  };
};

const getInsuranceData = (lineItem, insurances) => {
  const insurance = insurances.find(
    ins => ins.id === lineItem.primaryInsuranceId,
  );

  if (!insurance) return null;

  const copay = lineItem.lineItemDebits.reduce(
    (total, li) =>
      COPAY_CODES.includes(li.responsibilityType)
        ? total + li.debit.amount
        : total,
    0,
  );

  const deductible = lineItem.lineItemDebits.reduce(
    (total, li) =>
      DEDUCTIBLE_CODES.includes(li.responsibilityType)
        ? total + li.debit.amount
        : total,
    0,
  );

  const coinsurance = lineItem.lineItemDebits.reduce(
    (total, li) =>
      COINSURANCE_CODES.includes(li.responsibilityType)
        ? total + li.debit.amount
        : total,
    0,
  );

  const otherResponsibilities = lineItem.lineItemDebits.reduce(
    (total, li) =>
      ![...COPAY_CODES, ...DEDUCTIBLE_CODES, ...COINSURANCE_CODES].includes(
        li.responsibilityType,
      ) && !li.patientInsuranceId
        ? total + li.debit.amount
        : total,
    0,
  );

  const billedStatus = getLatestBilledStatus(lineItem);

  return {
    name: insurance.planName,
    payer: insurance.payerPlan?.alias,
    billedStatus,
    copay,
    deductible,
    coinsurance,
    otherResponsibilities,
  };
};

export const calculatePatientResponsibility = lineItem =>
  lineItem.lineItemDebits.reduce(
    (patientResponsibility, { patientInsuranceId, debit: { amount } }) =>
      patientInsuranceId
        ? patientResponsibility
        : patientResponsibility + amount,
    0,
  );

export const calculatePayerResponsibility = lineItem =>
  lineItem.lineItemDebits.reduce(
    (payerResponsibility, { patientInsuranceId, debit: { amount } }) =>
      patientInsuranceId ? payerResponsibility + amount : payerResponsibility,
    0,
  );

const calculatePayerPaid = lineItem =>
  lineItem.lineItemDebits.reduce(
    (payerPaid, { patientInsuranceId, debit: { allocations } }) =>
      patientInsuranceId
        ? payerPaid + allocations.reduce((acc, { amount }) => acc + amount, 0)
        : payerPaid,
    0,
  );

const calculatePatientPaymentsTotal = lineItem =>
  lineItem.lineItemDebits.reduce((patientPaymentsTotal, lineItemDebit) => {
    if (lineItemDebit.patientInsuranceId) {
      return patientPaymentsTotal;
    }

    return (
      patientPaymentsTotal +
      lineItemDebit.debit.allocations.reduce(
        (nonDiscountAllocationsTotal, allocation) =>
          allocation.credit?.payment.codeDiscountId
            ? nonDiscountAllocationsTotal
            : nonDiscountAllocationsTotal + allocation.amount,
        0,
      )
    );
  }, 0);

const calculateDiscountsTotal = lineItem =>
  lineItem.lineItemDebits.reduce((discountsTotal, lineItemDebit) => {
    if (lineItemDebit.patientInsuranceId) {
      return discountsTotal;
    }

    return (
      discountsTotal +
      lineItemDebit.debit.allocations.reduce(
        (discountAllocationsTotal, allocation) =>
          allocation.credit?.payment.codeDiscountId
            ? discountAllocationsTotal + allocation.amount
            : discountAllocationsTotal,
        0,
      )
    );
  }, 0);

const calculateAdjustmentsTotal = lineItem =>
  lineItem.adjustments.reduce(
    (adjustmentsTotal, { amount }) => adjustmentsTotal + amount,
    0,
  );

export const getOutstandingPatientBalance = lineItem =>
  calculatePatientResponsibility(lineItem) -
  calculatePatientPaymentsTotal(lineItem) -
  calculateDiscountsTotal(lineItem);

const getChargeDescription = lineItem =>
  `${lineItem.units} x ${lineItem.code} - ${lineItem.description}`;

const buildDefaultDetails = lineItem => {
  const billType =
    lineItem.billType === APPOINTMENT_BILLING_TYPE.CarePackage
      ? BILL_TYPES.CARE_PACKAGE
      : BILL_TYPES.SELF;
  const details = [`Bill type: ${billType}`];

  const patientResponsibility = calculatePatientResponsibility(lineItem);
  const adjustments = calculateAdjustmentsTotal(lineItem);

  details.push(`Billed: ${centsToCurrency(patientResponsibility)}`);

  const discounts = calculateDiscountsTotal(lineItem);

  if (discounts > 0) {
    details.push(`Discounts applied: ${centsToCurrency(discounts)}`);
  }

  if (adjustments > 0) {
    details.push(`Adjustments: ${centsToCurrency(adjustments)}`);
  }

  const payments = calculatePatientPaymentsTotal(lineItem);

  details.push(`Paid: ${centsToCurrency(payments)}`);

  return details.join(' | ');
};

const buildInsuranceDetails = (lineItem, insurance) => {
  const details = [];

  if (insurance.copay) {
    details.push(`Co-pay: ${centsToCurrency(insurance.copay)}`);
  }

  if (insurance.coinsurance) {
    details.push(`Co-ins: ${centsToCurrency(insurance.coinsurance)}`);
  }

  if (insurance.deductible) {
    details.push(`Ded: ${centsToCurrency(insurance.deductible)}`);
  }

  if (insurance.otherResponsibilities) {
    details.push(`Others: ${centsToCurrency(insurance.otherResponsibilities)}`);
  }

  const payments = calculatePatientPaymentsTotal(lineItem);

  details.push(`Paid: ${centsToCurrency(payments)}`);

  if (insurance.billedStatus) {
    details.push(`Status: ${insurance.billedStatus}`);
  }

  details.push(insurance.payer);

  return details.join(' | ');
};

const getChargeDetails = (lineItem, insurances) => {
  const insurance = getInsuranceData(lineItem, insurances);

  if (insurance) {
    return buildInsuranceDetails(lineItem, insurance);
  }

  return buildDefaultDetails(lineItem);
};

const getEstimatedOwedLabel = lineItemModelArray => {
  if (
    !lineItemModelArray.every(
      li => li.billType === APPOINTMENT_BILLING_TYPE.Insurance,
    )
  ) {
    return 'Total Owed';
  }

  const insuranceBalance = lineItemModelArray.reduce(
    (owed, li) =>
      owed + calculatePayerResponsibility(li) - calculatePayerPaid(li),
    0,
  );

  const chargesBilledPaidInFull = lineItemModelArray.every(
    li => li.billedStatus === 'PD',
  );

  if (chargesBilledPaidInFull && insuranceBalance === 0) {
    return 'Total Owed';
  }

  return 'Estimated Owed';
};

export const selectLineItemsAhead = liGroups => {
  if (liGroups.length) {
    return liGroups.map((group, idx) => {
      if (idx > 0) return group;
      return {
        ...group,
        items: group.items.map(li => ({
          ...li,
          selected: true,
          amount: getOutstandingPatientBalance(li),
        })),
      };
    });
  }

  return liGroups;
};

export const calculatePreallocationTotal = liGroups =>
  liGroups
    .flatMap(li => li.items)
    .filter(li => li.selected)
    .reduce((preallocationTotal, { amount }) => preallocationTotal + amount, 0);

export const calculatePreallocateInfoMap = preallocateInfoMap =>
  Object.values(preallocateInfoMap || {}).reduce(
    (acc, info) => acc + calculatePreallocationTotal(info.itemGroups),
    0,
  );

export const calculateCurrentBalance = (
  remainingPatientOwed,
  liGroups,
  creditsAmount = 0,
) =>
  remainingPatientOwed - calculatePreallocationTotal(liGroups) + creditsAmount;

export const getPreallocateAction = (
  selectedCharges,
  unallocatedCreditsUsed = 0,
) => {
  if (unallocatedCreditsUsed === 0) {
    return PREALLOCATE_ACTIONS.makePayment;
  }

  const totalChargesSelected = calculatePreallocationTotal(selectedCharges);

  if (unallocatedCreditsUsed >= totalChargesSelected) {
    return PREALLOCATE_ACTIONS.allocate;
  }

  return PREALLOCATE_ACTIONS.allocateAndPay;
};

export const mapLineItemsToModel = ({
  lineItems = [],
  encounters = [],
  insurances = [],
  providers = [],
  locations = [],
}) =>
  lineItems.map(li => ({
    id: li.id,
    selected: false,
    amount: getOutstandingPatientBalance(li),
    locationName: getLocationName(li, locations),
    allowedAmount: li.allowedAmount || 0,
    adjustments: li.adjustments || [],
    dateOfService: li.dateOfService,
    lineItemDebits: li.lineItemDebits,
    outstandingPatientBalance: getOutstandingPatientBalance(li),
    providerName: getProviderName(li, providers),
    billType: li.billType,
    billedStatus: getLatestBilledStatus(li),
    chargeDetails: getChargeDetails(li, insurances),
    chargeDescription: getChargeDescription(li),
    encounter: getEncounterData(li, encounters),
  }));

export const mapLineItemModelArrayToGroupModel = lineItemModelArray => ({
  title: getCardTitle(lineItemModelArray[0]),
  dateOfService:
    lineItemModelArray[0].encouter?.serviceDate ||
    lineItemModelArray[0].dateOfService,
  encounterChargesCount:
    lineItemModelArray.find(item => item.encounter?.encounterChargesCount)
      ?.encounter.encounterChargesCount || 1,
  estimatedOwedLabel: getEstimatedOwedLabel(lineItemModelArray),
  estimatedOwed: lineItemModelArray.reduce(
    (estimatedOwed, lineItem) =>
      estimatedOwed + getOutstandingPatientBalance(lineItem),
    0,
  ),
  appointmentType: lineItemModelArray[0].encounter?.appointmentType?.name,
  items: lineItemModelArray,
});

export const groupLineItemsModelsByEncounter = lineItemModels =>
  Object.values(
    lineItemModels.reduce((lineItemGroupModelDictionary, lineItem) => {
      if (!lineItem.encounter) {
        return {
          ...lineItemGroupModelDictionary,
          [lineItem.id]: [lineItem],
        };
      }

      lineItemGroupModelDictionary[lineItem.encounter.id] =
        lineItemGroupModelDictionary[lineItem.encounter.id] || [];

      lineItemGroupModelDictionary[lineItem.encounter.id].push(lineItem);
      return lineItemGroupModelDictionary;
    }, {}),
  )
    .map(mapLineItemModelArrayToGroupModel)
    .sort((a, b) => {
      if (a.dateOfService === b.dateOfService) {
        return 0;
      }

      const aDate = parseDate(a.dateOfService);
      const bDate = parseDate(b.dateOfService);
      return aDate.isBefore(bDate) ? 1 : -1;
    });

export const mapLineItemModelForAllocation = lineItemModel => ({
  id: lineItemModel.id,
  allowedAmount: lineItemModel.allowedAmount,
  debits: calculateAutoAllocateDebits(
    lineItemModel.lineItemDebits,
    {
      amount: lineItemModel.amount,
    },
    true,
  ),
  adjustments: lineItemModel.adjustments,
});

const makePaymentAction = async ({
  patient,
  preallocateItemGroups,
  onDismiss,
}) => {
  const amount = preallocateItemGroups
    ? calculatePreallocationTotal(preallocateItemGroups)
    : 0;

  let overlayProperties = {
    patientId: patient.id,
    patient,
    minimunAmount: amount,
    doNotDismissAll: true,
    chargeInfo: {
      amount,
      paymentType: { label: '' },
      alwaysShowActionBar: true,
      allowEditPaymentTypeAndAmount: true,
      hideVoidRefundButton: true,
    },
  };

  if (amount > 0) {
    const lineItems = preallocateItemGroups
      .flatMap(group => group.items)
      .filter(li => li.selected);

    overlayProperties = {
      ...overlayProperties,
      autoAllocate: {
        lineItems: lineItems.map(mapLineItemModelForAllocation),
        keepOpenAfterAllocation: true,
      },
      priorityAllocation: true,
    };
  }

  const result = await openOverlay(
    OVERLAY_KEYS.ADD_PATIENT_PAYMENT,
    overlayProperties,
  );

  return result?.paid ? onDismiss(result) : null;
};

const allocateAction = async ({
  patient,
  preallocateItemGroups,
  paymentId,
  creditsAmount = 0,
  onDismiss = () => {},
}) => {
  try {
    const result = await allocateCreditsToPreallocationLineItems({
      patientId: patient.id,
      lineItems: preallocateItemGroups
        .flatMap(group => group.items)
        .filter(li => li.selected)
        .map(item => ({ id: item.id, amount: item.amount })),
      creditsAmount,
      paymentId,
    });

    store.dispatch(openSuccess(ALLOCATE_ACTION_SUCCESS_BANNER));
    onDismiss(result);
  } catch (e) {
    console.error(e);
    store.dispatch(openError(ALLOCATE_ACTION_ERROR_BANNER));
  }
};

const allocateAndPayAction = async ({
  patient,
  preallocateItemGroups,
  creditsAmount = 0,
  onDismiss = () => {},
}) => {
  const amount =
    calculatePreallocationTotal(preallocateItemGroups) - creditsAmount;

  try {
    const result = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
      patientId: patient.id,
      patient,
      minimunAmount: amount,
      doNotDismissAll: true,
      chargeInfo: {
        amount,
        paymentType: { label: '' },
        alwaysShowActionBar: true,
        allowEditPaymentTypeAndAmount: true,
        hideVoidRefundButton: true,
      },
      priorityAllocation: true,
      paymentPostedCallback: ({ id }) =>
        allocateAction({
          patient,
          preallocateItemGroups,
          paymentId: id,
          creditsAmount,
        }),
    });

    if (result?.paid) {
      onDismiss(result);
    }
  } catch (e) {
    console.error(e);
    store.dispatch(openError(ALLOCATE_ACTION_ERROR_BANNER));
  }
};

export const preallocateCharges = ({
  patient,
  preallocateItemGroups,
  creditsAmount = 0,
  onDismiss = () => {},
}) => {
  if (!preallocateItemGroups) {
    return null;
  }

  const action = getPreallocateAction(preallocateItemGroups, creditsAmount);

  switch (action) {
    case PREALLOCATE_ACTIONS.makePayment:
      return makePaymentAction({ patient, preallocateItemGroups, onDismiss });
    case PREALLOCATE_ACTIONS.allocate:
      return allocateAction({
        patient,
        preallocateItemGroups,
        creditsAmount,
        onDismiss,
      });
    case PREALLOCATE_ACTIONS.allocateAndPay:
      return allocateAndPayAction({
        patient,
        preallocateItemGroups,
        creditsAmount,
        onDismiss,
      });
    default:
      return null;
  }
};

export const createPreallocateInfoMap = ({
  patientId,
  count,
  itemGroups,
  relationships,
}) => ({
  [patientId]: {
    count,
    pageNumber: 1,
    disableLoadMore: PREALLOCATE_SCREEN_PAGE_SIZE >= count,
    itemGroups,
  },
  ...relationships.reduce(
    (acc, { relatedPatientId }) => ({
      ...acc,
      [relatedPatientId]: {
        count: 0,
        pageNumber: 0,
        disableLoadMore: false,
        itemGroups: [],
      },
    }),
    {},
  ),
});

export const loadMoreUpdatePreallocateInfoMap = ({
  preallocateInfoMap,
  patientId,
  count,
  itemGroups,
}) => ({
  ...preallocateInfoMap,
  [patientId]: {
    count,
    pageNumber: preallocateInfoMap[patientId].pageNumber + 1,
    disableLoadMore:
      (preallocateInfoMap[patientId].pageNumber + 1) *
        PREALLOCATE_SCREEN_PAGE_SIZE >=
      count,
    itemGroups,
  },
});
